export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/profile/update', component: require('./components/users/Update.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/users/:id', component: require('./components/users/Employee.vue').default },
    { path: '/employees', component: require('./components/employees/Index.vue').default },
    { path: '/employees/leave-summaries/:id', component: require('./components/employees/LeaveSummaries.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '/leaves', component: require('./components/Leaves.vue').default },
    { path: '/leaves/all', component: require('./components/leaves/All.vue').default },
    { path: '/leaves/:id', component: require('./components/leaves/View.vue').default },
    { path: '/leaves-pending', component: require('./components/leaves/Pending.vue').default },
    { path: '/leaves-approval/:id', component: require('./components/leaves/Approval.vue').default },
    { path: '/leave-types', component: require('./components/LeaveTypes.vue').default },
    { path: '/leave-summaries', component: require('./components/LeaveSummaries.vue').default },
    { path: '/leave-quota', component: require('./components/LeaveQuota.vue').default },
    { path: '/shifts', component: require('./components/Shifts.vue').default },
    { path: '/rosters', component: require('./components/Rosters.vue').default },
    { path: '/rosters-calendar', component: require('./components/RosterCalendar.vue').default },
    { path: '/positions', component: require('./components/Positions.vue').default },
    { path: '/departments', component: require('./components/Departments.vue').default },
    { path: '/holidays', component: require('./components/Holidays.vue').default },
    { path: '/customers', component: require('./components/customers/Index.vue').default },
    { path: '/customers/create', component: require('./components/customers/create.vue').default },
    { path: '/customers/update/:id', component: require('./components/customers/update.vue').default },
    { path: '/tickets/create', component: require('./components/tickets/create.vue').default },

    { path: '/attendances', component: require('./components/attendances/index.vue').default },
    { path: '/attendances/report', component: require('./components/attendances/report.vue').default },
    { path: '/attendances/import', component: require('./components/attendances/import.vue').default },

    { path: '*', component: require('./components/NotFound.vue').default }
];
