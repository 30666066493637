<template>
    <section class="content">
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                            <i class="fa fa-plus-square"></i>
                            Add New
                            </button>
                            <button v-show="$gate.isAdmin() || $gate.isManager()" type="button" class="btn btn-sm btn-warning" @click="changeShift">
                                <i class="fas fa-sync"></i>
                                Change
                            </button>
                            <button v-show="$gate.isAdmin() || $gate.isManager()" type="button" class="btn btn-sm btn-info" @click="popupImportRoster">
                                <i class="fas fa-sync"></i>
                                Import
                            </button>
                        </div>
                        <div class="col-12">
                            <div>
                            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal -->
             <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Create New Roster</h5>
                        <h5 class="modal-title" v-show="editmode">Update Roster</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <!-- <form @submit.prevent="createUser"> -->

                    <form @submit.prevent="editmode ? updateRoster() : createRoster()">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Start <span class="text-danger">*</span></label>
                                        <date-picker v-model="form.date" valueType="format" :class="{ 'is-invalid': form.errors.has('date') }"></date-picker>
                                        <has-error :form="form" field="date"></has-error>
                                    </div>
                                </div>
                                    <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>End <span class="text-danger">*</span></label>
                                        <date-picker v-model="form.date_end" valueType="format" :class="{ 'is-invalid': form.errors.has('date_end') }"></date-picker>
                                        <has-error :form="form" field="date_end"></has-error>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Shift <span class="text-danger">*</span></label>
                                <select class="form-control" :class="{ 'is-invalid': form.errors.has('shift_id') }" v-model="form.shift_id">
                                <option 
                                    v-for="(cat,index) in shifts" :key="index"
                                    :value="cat.id"
                                    :selected="index == form.shift_id">{{ cat.name }} ({{ cat.start_time }}-{{ cat.end_time }})</option>
                                </select>
                                <has-error :form="form" field="shift_id"></has-error>
                            </div>

                            <div class="form-group">
                                <label>Employees <span class="text-danger">*</span></label>
                                <multiselect 
                                    select-Label=""
                                    selected-Label=""
                                    deselect-Label=""
                                    placeholder="Select Employee"
                                    :showLabels="false"
                                    v-model="value" 
                                    :options="employees"
                                    :multiple="true"
                                    track-by="id"
                                    :custom-label="customLabel"
                                    :close-on-select="false"
                                    @select=onSelect($event)
                                    @remove=onRemove($event)>
                                    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="onSelect(scope.option)">
                                        <input class="test" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
                                        {{ scope.option.firstname }} {{ scope.option.lastname }}
                                    </span>
                                </multiselect>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                            <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="editModel" tabindex="-1" role="dialog" aria-labelledby="editModel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Create New Roster</h5>
                        <h5 v-if="rosters.data !== undefined" class="modal-title" v-show="editmode">Date: {{rosters.data.length===0 ? '':rosters.data[0].date}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body table-responsive">
                    <table class="table table-hover">
                    <tbody>
                        <tr v-for="roster in rosters.data" :key="roster.id">
                        <td :style="{ color:'#000000', backgroundColor:''+roster.shift.bgcolor}">{{roster.shift.start_time}}-{{roster.shift.end_time}} | {{roster.employee.nickname}}</td>
                        <td :style="{ color:'#000000', backgroundColor:''+roster.shift.bgcolor}" width="100px">
                            <button v-show="$gate.isAdmin() || $gate.isManager()" type="button" class="btn btn-sm btn-danger" @click="deleteRoster(roster)">
                                <i class="fa fa-trash"></i> Delete
                            </button>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    </div>
                    
                    </div>
                </div>
            </div>

            <div class="modal fade" id="changeShift" tabindex="-1" role="dialog" aria-labelledby="changeShift" aria-hidden="true">
                 <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Change Shift</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="changeRoster()">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Employee</label>
                                        <select class="form-control" v-model="form2.employee_id" :disabled="$gate.isUser()">
                                        <option 
                                            v-for="(cat,index) in employees" :key="index"
                                            :value="cat.id"
                                            :selected="index == form2.employee_id">{{ cat.firstname }} {{ cat.lastname }}</option>
                                        </select>
                                        <has-error :form="form2" field="employee_id"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label>Date</label>
                                        <date-picker v-model="form2.date" valueType="format"></date-picker>
                                        <has-error :form="form2" field="date"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label>Shift</label>
                                        <select class="form-control" v-model="form2.shift_id">
                                        <option 
                                            v-for="(cat,index) in shifts" :key="index"
                                            :value="cat.id"
                                            :selected="index == form2.shift_id">{{ cat.name }} ({{ cat.start_time }}-{{ cat.end_time }})</option>
                                        </select>
                                        <has-error :form="form2" field="shift_id"></has-error>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Employee</label>
                                        <select class="form-control" v-model="form2.employee_id2" :disabled="$gate.isUser()">
                                        <option 
                                            v-for="(cat,index) in employees" :key="index"
                                            :value="cat.id"
                                            :selected="index == form2.employee_id2">{{ cat.firstname }} {{ cat.lastname }}</option>
                                        </select>
                                        <has-error :form="form2" field="employee_id2"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label>Date</label>
                                        <date-picker v-model="form2.date2" valueType="format"></date-picker>
                                        <has-error :form="form2" field="date2"></has-error>
                                    </div>
                                    <div class="form-group">
                                        <label>Shift</label>
                                        <select class="form-control" v-model="form2.shift_id2">
                                        <option 
                                            v-for="(cat,index) in shifts" :key="index"
                                            :value="cat.id"
                                            :selected="index == form2.shift_id2">{{ cat.name }} ({{ cat.start_time }}-{{ cat.end_time }})</option>
                                        </select>
                                        <has-error :form="form2" field="shift_id2"></has-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Update</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </form>
                    </div>
                 </div>
            </div>

            <div class="modal fade" id="importRoster" tabindex="-1" role="dialog" aria-labelledby="importRoster" aria-hidden="true">
                 <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Import Roster</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="importRoster()" enctype="multipart/form-data">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Employee</label>
                                <input type="file" id="file" ref="fileupload" name="fileupload" v-on:change="onFileChange">
                            </div>
                        </div>
                         <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Import</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </form>
                    </div>
                 </div>
            </div>
        </div>
    </section>
</template>
<script>
    import FullCalendar from '@fullcalendar/vue'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import DatePicker from 'vue2-datepicker'
    import moment from 'moment'
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            FullCalendar,
            DatePicker,
            Multiselect,
        },
        data() {
            return {
                calendarOptions: {
                    plugins: [ dayGridPlugin, interactionPlugin ],
                    initialView: 'dayGridMonth',
                    fixedWeekCount: false,
                    eventClick: this.handleEventClick,
                    events: function (fetchInfo, successCallback, failureCallback) {
                        axios.get('/api/rosters/list', {
                            params: {
                                'start': moment(fetchInfo.start).format('YYYY-MM-DD'),
                                'end': moment(fetchInfo.end).format('YYYY-MM-DD'),
                            }
                        }).then(response => {
                            successCallback(response.data.data);
                        });
                    },
                },
                editmode: false,
                rosters : {},
                form: new Form({
                    id : '',
                    date: '',
                    date_end: '',
                    shift_id: '',
                    employee_id: '',
                    employee_ids: []
                }),
                form2: new Form({
                    id : '',
                    date: '',
                    shift_id: '',
                    employee_id: '',
                    id2 : '',
                    date2: '',
                    shift_id2: '',
                    employee_id2: '',
                }),
                file: '',
                shifts: [],
                employees: [],
                value: []
            }
        },
        methods: {
            onFileChange(e){
                this.file = e.target.files[0];
            },
            getEvents() {
                let calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.refetchEvents();
            },
            loadShifts(){
              axios.get("/api/shifts/list").then(({ data }) => (this.shifts = data.data));
            },
            loadEmployees(){
              axios.get("/api/employees/list").then(({ data }) => (this.employees = data.data));
            },
            handleEventClick(info) {
                var date = moment(info.event.start).format('YYYY-MM-DD');
                axios.get('/api/rosters/info', {
                    params: {
                        'shift_id': info.event.id,
                        'date': date,
                    }
                }).then(response => {
                    this.rosters = response.data
                    this.editmode = true;
                    this.form.reset();
                    $('#editModel').modal('show');

                });
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                this.value = [];
                this.loadEmployees();

                if(this.$gate.isUser()) {
                    axios.get("/api/employees/me").then(({ data }) => (this.form.employee_id = data.data.id));
                }
                $('#addNew').modal('show');
            },
            changeShift() {
                this.form2.reset();
                //axios.get("/api/employees/me").then(({ data }) => (this.form.employee_id = data.data.id));
                $('#changeShift').modal('show');
            },
            changeRoster() {
                this.form2.put('/api/rosters/change_shift')
                .then((response)=>{
                    $('#changeShift').modal('hide');
                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.getEvents();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            popupImportRoster() {
                this.$refs.fileupload.value=null;
                $('#importRoster').modal('show');
            },
            importRoster() {
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }

                let formData = new FormData();
                formData.append('file', this.file);

                axios.post('/api/rosters/import', formData, config)
                .then((response)=>{
                    $('#importRoster').modal('hide');
                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.getEvents();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            createRoster(){
                this.form.employee_ids = this.value;
                this.form.post('/api/rosters')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.getEvents();

                    
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            deleteRoster(roster){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/rosters/'+roster.id).then((response)=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');

                                    

                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });

                              axios.get('/api/rosters/info', {
                                params: {
                                    'shift_id': roster.shift_id,
                                    'date': roster.date,
                                }
                            }).then(response => {
                                this.rosters = response.data
                            });

                            this.getEvents();
                         }
                    })
            },
            customLabel(option) {
                return `${option.firstname} ${option.lastname}`;
            },
            onSelect(option) {
                //console.log("Added");
                let index = this.employees.findIndex(item => item.id==option.id);
                this.employees[index].checked = true;
                //console.log(option.id + "  Clicked!! " + option.checked);
            },

            onRemove(option) {
                //console.log("Removed");
                let index = this.employees.findIndex(item => item.id==option.id);
                this.employees[index].checked = false;
                //console.log(option.id + "  Removed!! " + option.checked);
            }
        },
        created() {
            this.$Progress.start();
            //this.getEvents();
            this.loadShifts();
            this.loadEmployees();
            this.$Progress.finish();
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@media print{
    @page {
        size: landscape
    }

    body {
        zoom: 0.85;
    }

    .fc-col-header-cell {
        border:2px solid #ff0000 !important;
    }

}

</style>