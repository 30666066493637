<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Leave Summaries</h3>

                <div class="card-tools">
                  
                  <!--
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Leave Type</th>
                      <th width="100px">Year</th>
                      <th width="100px">Carry Forward</th>
                      <th width="100px">Entitlement</th>
                      <th width="100px">Total</th>
                      <th width="100px">Used</th>
                      <th width="100px">Balance</th>
                      <th width="100px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="obj in leave_summaries" :key="obj.id">
                      <td class="text-capitalize">{{obj.name}}</td>
                      <td>{{obj.emp_year.y}}</td>
                      <td>{{obj.init_qty}}</td>
                      <td>{{obj.current_qty}}</td>
                      <td>{{obj.total}}</td>
                      <td class="text-capitalize"><span>{{obj.used}}</span></td>
                      <td class="text-capitalize"><span>{{obj.balance}}</span></td>
                      <td>
                        <a href="#" @click="editModal(obj)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Leave Type</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }" :readonly="true">
                            <has-error :form="form" field="name"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Initial</label>
                            <input v-model="form.init_qty" type="text" name="init_qty"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('init_qty') }">
                            <has-error :form="form" field="init_qty"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Current</label>
                            <input v-model="form.current_qty" type="text" name="current_qty"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('current_qty') }" :readonly="true">
                            <has-error :form="form" field="current_qty"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Total Days</label>
                            <input v-model="form.total" type="text" name="total"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('total') }" :readonly="true">
                            <has-error :form="form" field="total"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
          DatePicker,
        },
        data () {
            return {
                editmode: false,
                leave_summaries : {},
                form: new Form({
                    id : '',
                    name: '',
                    max_day: '',
                    total: '',
                    init_qty: '',
                    current_qty: '',
                    used: '',
                    balance: '',
                    leave_summary_id : '',
                    employee_id : ''
                }),
            }
        },
        methods: {
            loadLeaveSummaries(){
                axios.get("/api/employees/leave-summaries/"+this.$route.params.id).then(({ data }) => (this.leave_summaries = data.data));
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('/api/employees/leave-summaries/'+this.$route.params.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadLeaveSummaries();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(user){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(user);
            }
        },
        mounted() {
            console.log('User Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadLeaveSummaries();
            this.$Progress.finish();
        }
    }
</script>
